import { differenceInMinutes } from 'date-fns';
import _, { forEach } from 'lodash';

import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';

import { DeliveryUtils } from '@arrivage-distribution/common/utils/delivery.utils';
import { PickupUtils } from '@arrivage-distribution/vendor/utils/pickup.utils';
import {
  DatesAvailability,
  PriceListData,
} from '@arrivage-price-lists/model/price-list.model';
import { DeliveryZonesService } from '@arrivage-services/delivery-zones.service';
import { LangUtils } from '@arrivage-util/lang.utils';
import {
  Delivery,
  Offer,
  RelationshipInfo,
  WithId,
} from '@arrivage/model/dist/src/model';

export interface ShareAllOffersDialogData {
  [offerId: string]: PriceListData;
}

export interface ShareAllOffersDialogResponse {
  offerId: string;
  relationshipIds: string[];
  message: string;
  attachPdf: boolean;
  includeInactiveProducts: boolean;
}

@Component({
  selector: 'app-share-all-offers-dialog',
  templateUrl: './share-all-offers-dialog.component.html',
  styleUrls: ['./share-all-offers-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareAllOffersDialogComponent implements OnInit, AfterViewInit {
  readonly displayedColumns = [
    'select',
    'customers',
    'lastShareOfferTimestamp',
  ];
  dataValues: PriceListData[];
  dataSourceByOffer: {
    [offerId: string]: MatTableDataSource<RelationshipInfo & WithId>;
  } = {};
  messageByOfferId: {
    [offerId: string]: {
      message: string;
    };
  } = {};
  dataSelectionByOffer: {
    [offerId: string]: SelectionModel<RelationshipInfo & WithId>;
  } = {};
  form: UntypedFormGroup = this.fb.group({
    message: [null],
  });
  initialized = false;
  numberOfOffers: number;
  previousOfferId: string;
  isPdfAttach: { [offerId: string]: boolean } = {};
  includeInactiveProducts: { [offerId: string]: boolean } = {};

  @Output()
  openPdf = new EventEmitter<{
    data: PriceListData;
    includeInactiveProducts?: boolean;
  }>();

  @ViewChild(MatSort)
  set matSort(value: MatSort) {
    if (value) {
      forEach(this.dataSourceByOffer, (item) => {
        item.sort = value;
        item.sortingDataAccessor = this.sortingDataAccessor;
      });
    }
  }

  @ViewChildren(MatPaginator)
  paginator: QueryList<MatPaginator>;

  @ViewChild(MatStepper)
  stepper: MatStepper;

  constructor(
    private fb: UntypedFormBuilder,
    private deliveryZonesService: DeliveryZonesService,
    @Inject(MAT_DIALOG_DATA) public data: ShareAllOffersDialogData,
    public dialogRef: MatDialogRef<
      ShareAllOffersDialogComponent,
      ShareAllOffersDialogResponse[]
    >
  ) {}

  get selectedIndex(): number {
    return this.stepper?.selectedIndex;
  }

  get nothingIsEligibleForSharing(): boolean {
    return _.isEmpty(this.data);
  }

  someHaveDeliveryIssues(offer: Offer & WithId): boolean {
    return this.data[offer.id].customersAccess.allowed.some(
      (c) => !this.isEligibleForSharing(offer, c)
    );
  }

  someHaveOnlyPickup(offer: Offer & WithId): boolean {
    return this.data[offer.id].customersAccess.allowed.some((c) =>
      this.isOnlyPickupAvailable(offer, c)
    );
  }

  ngOnInit(): void {
    this.dataValues = Object.values(this.data);
    this.numberOfOffers = Object.keys(this.data).length - 1;
    this.previousOfferId = '';
    _.forEach(this.data, (elem, key) => {
      this.isPdfAttach[key] = true;
      this.includeInactiveProducts[key] = true;
    });
  }

  ngAfterViewInit(): void {
    Object.keys(this.data).forEach((offerId, i) => {
      this.dataSourceByOffer[offerId] = new MatTableDataSource();
      this.dataSourceByOffer[offerId].paginator = this.paginator.get(i);
      this.dataSourceByOffer[offerId].data = LangUtils.normalizedSortBy(
        this.data[offerId].customersAccess?.allowed,
        (r) => r.name
      );
      this.dataSelectionByOffer[offerId] = new SelectionModel<
        RelationshipInfo & WithId
      >(
        true,
        this.dataSourceByOffer[offerId].data.filter((d) =>
          this.isEligibleForSharing(this.data[offerId].offer, d)
        )
      );
    });
    this.initialized = true;
  }

  get messageControl(): AbstractControl {
    return this.form.get('message');
  }

  saveMessageForOneOffer(offerId: string): void {
    this.messageByOfferId[offerId] = {
      message: this.messageControl.value,
    };
    this.previousOfferId = offerId;
  }

  trackById(index: number, item: RelationshipInfo & WithId) {
    return item.id;
  }

  onOpenPdf(item: PriceListData) {
    this.isPdfAttach[item.offer.id]
      ? this.openPdf.emit({
          data: item,
          includeInactiveProducts: this.includeInactiveProducts[item.offer.id],
        })
      : this.openPdf.emit({ data: item });
  }

  onAttachPdfChange(offerId: string, checked: boolean) {
    this.isPdfAttach[offerId] = checked;
    this.includeInactiveProducts[offerId] = checked;
  }

  select(offer: Offer & WithId, row: RelationshipInfo & WithId) {
    if (this.isEligibleForSharing(offer, row)) {
      this.dataSelectionByOffer[offer.id].toggle(row);
    }
  }

  async share() {
    const shareAllOffersDialogResponses = await Promise.all(
      Object.keys(this.dataSelectionByOffer).map(async (offerId) => {
        return {
          offerId,
          relationshipIds: this.dataSelectionByOffer[offerId].selected.map(
            (selected) => selected.relationshipId
          ),
          message: this.messageByOfferId[offerId].message,
          attachPdf: this.isPdfAttach[offerId],
          includeInactiveProducts: this.includeInactiveProducts[offerId],
        };
      })
    );
    this.dialogRef.close(shareAllOffersDialogResponses);
  }

  /* Whether the number of selected elements matches the total number of rows. */
  isAllSelected(offer: Offer & WithId) {
    const numSelected = this.dataSelectionByOffer[offer.id].selected.length;
    const numRows = this.dataSourceByOffer[offer.id].data.filter((row) =>
      this.isEligibleForSharing(offer, row)
    ).length;
    return numSelected === numRows;
  }

  /* Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(offer: Offer & WithId) {
    this.isAllSelected(offer)
      ? this.dataSelectionByOffer[offer.id].clear()
      : this.dataSourceByOffer[offer.id].data
          .filter((row) => this.isEligibleForSharing(offer, row))
          .forEach((row) => this.dataSelectionByOffer[offer.id].select(row));
  }

  isEligibleForSharing(
    offer: Offer & WithId,
    relationship: RelationshipInfo & WithId
  ) {
    const publicDeliveriesApplicableToCustomer: (Delivery & WithId)[] =
      DeliveryUtils.getApplicablePublicDeliveriesForLocation(
        offer.vendorPublicDeliveries ?? [],
        relationship.location,
        this.deliveryZonesService
      );

    return (
      (relationship.deliveryId &&
        DeliveryUtils.hasOpenDeliveryDate(
          this.data[offer.id].deliveryByRelationship[
            relationship.relationshipId
          ],
          {
            upperBound: offer.expirationDate,
          }
        )) ||
      _.some(publicDeliveriesApplicableToCustomer, (delivery) =>
        DeliveryUtils.hasOpenDeliveryDate(delivery, {
          upperBound: offer.expirationDate,
        })
      ) ||
      this.arePickupOptionsAvailable(offer)
    );
  }

  arePickupOptionsAvailable(offer: Offer & WithId): boolean {
    return (
      PickupUtils.getPickupAvailability(offer.pickups, {
        upperBound: offer.expirationDate,
      }) === DatesAvailability.AVAILABLE
    );
  }

  isOnlyPickupAvailable(
    offer: Offer & WithId,
    relationship: RelationshipInfo & WithId
  ) {
    const publicDeliveriesApplicableToCustomer: (Delivery & WithId)[] =
      DeliveryUtils.getApplicablePublicDeliveriesForLocation(
        offer.vendorPublicDeliveries ?? [],
        relationship.location,
        this.deliveryZonesService
      );

    const assignedDeliveryHasOpenDeliveryDate = relationship.deliveryId
      ? DeliveryUtils.hasOpenDeliveryDate(
          this.data[offer.id].deliveryByRelationship[
            relationship.relationshipId
          ],
          { upperBound: offer.expirationDate }
        )
      : false;

    const publicDeliveriesHasOpenDeliveryDate = _.some(
      publicDeliveriesApplicableToCustomer,
      (publicDelivery) =>
        DeliveryUtils.hasOpenDeliveryDate(publicDelivery, {
          upperBound: offer.expirationDate,
        })
    );

    return (
      this.arePickupOptionsAvailable(offer) &&
      !assignedDeliveryHasOpenDeliveryDate &&
      !publicDeliveriesHasOpenDeliveryDate
    );
  }

  elapsedTimeInMinutes(lastShareOfferTimestamp: number) {
    return lastShareOfferTimestamp
      ? differenceInMinutes(new Date().getTime(), lastShareOfferTimestamp)
      : Number.MAX_SAFE_INTEGER;
  }

  elapsedTimeLessThanHour(lastShareOfferTimestamp: number) {
    return this.elapsedTimeInMinutes(lastShareOfferTimestamp) < 60;
  }

  isMoreThanHour(offer: Offer & WithId) {
    return !this.dataSelectionByOffer[offer.id]?.selected.find((row) =>
      this.elapsedTimeLessThanHour(row.lastShareOfferTimestamp)
    );
  }

  private sortingDataAccessor(
    customer: RelationshipInfo & WithId,
    headerId: string
  ): string | number {
    switch (headerId) {
      case 'customers':
        return LangUtils.normalizeString(customer.name);
      case 'lastShareOfferTimestamp':
        return customer.lastShareOfferTimestamp
          ? customer.lastShareOfferTimestamp
          : 1;
      default:
        return customer[headerId] ? customer[headerId] : 1;
    }
  }

  goForward() {
    this.saveMessageForOneOffer(this.currentOfferId);
    if (this.stepper.selectedIndex === Object.keys(this.data).length - 1) {
      this.share();
    } else {
      this.stepper.next();
    }
  }

  getMessageFromPreviousOffer() {
    return this.messageByOfferId[this.previousOfferId]?.message &&
      this.stepper?.selectedIndex > 0
      ? this.messageByOfferId[this.previousOfferId].message
      : '';
  }

  get currentOfferId() {
    return this.dataValues[this.stepper?.selectedIndex]?.offer.id;
  }
}

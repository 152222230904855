<ng-container
  *transloco="
    let t;
    scope: 'offers';
    read: 'offers.scheduled-date-and-time-dialog'
  "
>
  <div class="mat-dialog-content flex-column gap-4rem">
    <h2 class="mat-dialog-title space-bottom text-xl">
      {{ t('title') }}
    </h2>
    <div class="mat-typography mat-dialog-content no-bold flex-column gap-2rem">
      <div>{{ t('message') }}</div>

      <form class="flex-column gap-2rem" [formGroup]="form">
        <!-- DATE -->
        <mat-form-field appearance="outline" class="medium-width">
          <mat-label>{{ t('date-label') }}</mat-label>
          <input
            matInput
            data-testid="date-picker-scheduled-date-testid"
            formControlName="scheduledDate"
            [min]="minDate"
            [max]="MAX_DATE"
            [matDatepicker]="selectedDate"
            (click)="selectedDate.open()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="selectedDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #selectedDate></mat-datepicker>
        </mat-form-field>

        <!-- TIME -->
        <mat-form-field class="medium-width">
          <mat-label>{{ t('time-label') }}</mat-label>
          <mat-select
            name="selectedTime"
            formControlName="selectedTime"
            data-testid="scheduled-time-select-testid"
          >
            @for (time of TIME_OF_DAY_OPTIONS; track time) {
              <mat-option [value]="time" [disabled]="!isTimeAvailable(time)">
                {{ time }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>

        @if (form.dirty && form.invalid) {
          <mat-error data-testid="scheduled-date-error-testid">{{
            t('error')
          }}</mat-error>
        }
      </form>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
      {{ t('cancel-button') }}
    </button>

    <button mat-raised-button color="accent" (click)="confirm()">
      <span>{{ t('confirm-button') }}</span>
    </button>
  </mat-dialog-actions>
</ng-container>

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { createSelectors } from '@arrivage-store/generators';
import { selectRouteParam } from '@arrivage-store/routing/router-state.selectors';

import { ScheduledOffer } from '@arrivage/model/dist/src/model/scheduled-offer';

import { adapter } from './scheduled-offer.reducer';
import { ScheduledOfferState, State } from './scheduled-offer.state';

export const getScheduledOfferState =
  createFeatureSelector<ScheduledOfferState>('scheduledOffer');

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  connecting,
  connected,
  connectState,
  queryFailure,
  addFailure,
  setFailure,
  updateFailure,
  removeFailure,
  getById,
  selectActiveItem,
  isLoadingActiveItem,
  selectActiveItemState,
} = createSelectors<ScheduledOffer, State>(adapter, getScheduledOfferState);

export const getCurrentScheduledOfferId = createSelector(
  selectRouteParam('scheduledOfferId'),
  (scheduledOfferId) => scheduledOfferId
);

export const selectSortedScheduledOffersByEffectiveTime = createSelector(
  selectAll,
  (scheduledOffers) =>
    scheduledOffers.sort((a, b) => (a.effectiveTime > b.effectiveTime ? 1 : -1))
);

export const selectUnappliedScheduledOffers = createSelector(
  selectSortedScheduledOffersByEffectiveTime,
  (scheduledOffers) =>
    scheduledOffers.filter((scheduledOffer) => !scheduledOffer.hasBeenApplied)
);

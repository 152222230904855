import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { translate } from '@jsverse/transloco';

import {
  DateAndTimeDialogComponent,
  DateAndTimeDialogData,
  DateAndTimeDialogResponse,
} from '@arrivage-components/date-and-time-dialog/date-and-time-dialog.component';
import {
  DialogConfirmationComponent,
  DialogConfirmationData,
} from '@arrivage-components/dialog-confirmation/dialog-confirmation.component';
import {
  FilteredDateRangePickerDialogComponent,
  FilteredDateRangePickerDialogResponse,
} from '@arrivage-components/filtered-date-range-picker-dialog/filtered-date-range-picker-dialog.component';
import { ProductAndFormatInfoDialogComponent } from '@arrivage-components/product-and-format-info-dialog/product-and-format-info-dialog.component';
import { ProductInfoDialogComponent } from '@arrivage-components/product-info-dialog/product-info-dialog.component';
import { ShareButtonsDialogComponent } from '@arrivage-components/share-buttons/share-buttons-dialog/share-buttons-dialog.component';
import {
  SystemMessageDialogComponent,
  SystemMessageDialogData,
} from '@arrivage-components/system-message/system-message-dialog.component';
import { SystemMessageType } from '@arrivage-components/system-message/system-message.component';
import {
  YesNoDialogComponent,
  YesNoDialogData,
  YesNoResponse,
} from '@arrivage-components/yes-no-dialog/yes-no-dialog.component';
import {
  OfferItem,
  OrderItem,
  OrderItemProduct,
  Product,
  WithId,
} from '@arrivage/model/dist/src/model';
import { startOfDay } from 'date-fns';

export namespace DialogUtils {
  export function openConfirmationDialog(
    dialog: MatDialog,
    titleTranslationKey: string,
    messageTranslationKey: string,
    options: {
      additionalValidationTranslationKey?: string;
      confirmationForDeleting?: boolean;
      translationContext?: {};
    } = {
      additionalValidationTranslationKey: null,
      confirmationForDeleting: false,
      translationContext: {},
    }
  ): MatDialogRef<
    DialogConfirmationComponent,
    false | { additionalValidation: boolean }
  > {
    return dialog.open<
      DialogConfirmationComponent,
      DialogConfirmationData,
      false | { additionalValidation: boolean }
    >(DialogConfirmationComponent, {
      data: {
        title: translate(titleTranslationKey, options.translationContext),
        message: translate(messageTranslationKey, options.translationContext),
        additionalValidation: options.additionalValidationTranslationKey
          ? {
              message: translate(
                options.additionalValidationTranslationKey,
                options.translationContext
              ),
            }
          : null,
        confirmationForDeleting: options.confirmationForDeleting,
      },
      width: '600px',
      autoFocus: false,
    });
  }

  export function openYesNoDialog(
    dialog: MatDialog,
    titleTranslationKey: string,
    messageTranslationKey: string,
    canCancel: boolean = true
  ): MatDialogRef<YesNoDialogComponent, YesNoResponse> {
    return dialog.open<YesNoDialogComponent, YesNoDialogData, YesNoResponse>(
      YesNoDialogComponent,
      {
        data: {
          title: translate(titleTranslationKey),
          message: translate(messageTranslationKey),
          canCancel: canCancel,
        },
        width: '600px',
        autoFocus: false,
      }
    );
  }

  export function openFilteredDateRangePickerDialog(
    dialog: MatDialog
  ): MatDialogRef<
    FilteredDateRangePickerDialogComponent,
    FilteredDateRangePickerDialogResponse
  > {
    return dialog.open<
      FilteredDateRangePickerDialogComponent,
      FilteredDateRangePickerDialogResponse
    >(FilteredDateRangePickerDialogComponent, {
      width: '600px',
    });
  }

  export function openSystemMessageDialog(
    dialog: MatDialog,
    type: SystemMessageType,
    titleTranslationKey: string,
    messageTranslationKey: string,
    descriptionTranslationKey: string = '',
    translationContext: {} = {},
    disableClose = false
  ): MatDialogRef<SystemMessageDialogComponent, void> {
    return dialog.open<
      SystemMessageDialogComponent,
      SystemMessageDialogData,
      void
    >(SystemMessageDialogComponent, {
      data: {
        type: type,
        title: translate(titleTranslationKey, translationContext),
        message: translate(messageTranslationKey, translationContext),
        description: translate(descriptionTranslationKey, translationContext),
      },
      disableClose: disableClose,
      width: '600px',
      autoFocus: false,
    });
  }

  export function openProductInfoDialog(
    dialog: MatDialog,
    product: (Product & WithId) | (OrderItemProduct & WithId),
    offerItems: OfferItem[],
    isExtraSmallScreen: boolean,
    orderItem: OrderItem = null,
    showPriceAndStock: boolean = true
  ): MatDialogRef<
    ProductInfoDialogComponent,
    {
      product: (Product & WithId) | (OrderItemProduct & WithId);
      offerItems: OfferItem[];
      isExtraSmallScreen: boolean;
      orderItem: OrderItem;
      showPriceAndStock: boolean;
    }
  > {
    if (isExtraSmallScreen) {
      return dialog.open<
        ProductInfoDialogComponent,
        {
          product: (Product & WithId) | (OrderItemProduct & WithId);
          offerItems: OfferItem[];
          isExtraSmallScreen: boolean;
          orderItem: OrderItem;
          showPriceAndStock: boolean;
        }
      >(ProductInfoDialogComponent, {
        data: {
          product,
          offerItems,
          isExtraSmallScreen,
          orderItem,
          showPriceAndStock,
        },
        autoFocus: false,
        panelClass: 'full-dialog',
      });
    } else {
      return dialog.open<
        ProductInfoDialogComponent,
        {
          product: (Product & WithId) | (OrderItemProduct & WithId);
          offerItems: OfferItem[];
          isExtraSmallScreen: boolean;
          orderItem: OrderItem;
          showPriceAndStock: boolean;
        }
      >(ProductInfoDialogComponent, {
        data: {
          product,
          offerItems,
          isExtraSmallScreen,
          orderItem,
          showPriceAndStock,
        },
        autoFocus: false,
        maxHeight: '80vh',
        width: '800px',
      });
    }
  }

  export function openProductAndFormatInfoDialog(
    dialog: MatDialog,
    product: Product & WithId,
    currentFormatId: string,
    offerItems: OfferItem[],
    isExtraSmallScreen: boolean,
    showPriceAndStock: boolean = true
  ): MatDialogRef<
    ProductAndFormatInfoDialogComponent,
    {
      product: Product & WithId;
      currentFormatId: string;
      offerItems: OfferItem[];
      isExtraSmallScreen: boolean;
      showPriceAndStock: boolean;
    }
  > {
    if (isExtraSmallScreen) {
      return dialog.open<
        ProductAndFormatInfoDialogComponent,
        {
          product: Product & WithId;
          currentFormatId: string;
          offerItems: OfferItem[];
          isExtraSmallScreen: boolean;
          showPriceAndStock: boolean;
        }
      >(ProductAndFormatInfoDialogComponent, {
        data: {
          product,
          currentFormatId,
          offerItems,
          isExtraSmallScreen,
          showPriceAndStock,
        },
        autoFocus: false,
        panelClass: 'full-dialog',
      });
    } else {
      return dialog.open<
        ProductAndFormatInfoDialogComponent,
        {
          product: Product & WithId;
          currentFormatId: string;
          offerItems: OfferItem[];
          isExtraSmallScreen: boolean;
          showPriceAndStock: boolean;
        }
      >(ProductAndFormatInfoDialogComponent, {
        data: {
          product,
          currentFormatId,
          offerItems,
          isExtraSmallScreen,
          showPriceAndStock,
        },
        autoFocus: false,
        maxHeight: '80vh',
        width: '800px',
      });
    }
  }

  export function openShareButtonsDialog(
    dialog: MatDialog,
    isExtraSmallScreen?: boolean
  ): MatDialogRef<ShareButtonsDialogComponent> {
    if (isExtraSmallScreen) {
      return dialog.open<ShareButtonsDialogComponent>(
        ShareButtonsDialogComponent,
        {
          panelClass: 'full-dialog',
        }
      );
    } else {
      return dialog.open<ShareButtonsDialogComponent>(
        ShareButtonsDialogComponent,
        {
          maxWidth: '600px',
        }
      );
    }
  }

  export function openDateAndTimeDialog(
    dialog: MatDialog,
    effectiveTime?: Date
  ): MatDialogRef<DateAndTimeDialogComponent> {
    return dialog.open<
      DateAndTimeDialogComponent,
      DateAndTimeDialogData,
      DateAndTimeDialogResponse
    >(DateAndTimeDialogComponent, {
      disableClose: true,
      autoFocus: false,
      width: '600px',
      data: effectiveTime
        ? {
            initialDate: startOfDay(effectiveTime),
            initialTime: {
              hours: effectiveTime.getHours(),
              minutes: effectiveTime.getMinutes(),
            },
          }
        : null,
    });
  }
}

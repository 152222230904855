@if (label) {
  <div
    class="text-s"
    [class.bolder]="bolder"
    [class.primary]="type === 'primary'"
    [class.accent]="type === 'accent'"
    [class.warn]="type === 'warn'"
    [class.local]="type === 'local'"
    [class.organic]="type === 'organic'"
    [class.hub]="type === 'hub'"
    [class.imported-item]="type === 'imported-item'"
    [class.lighter-yellow-color]="type === 'lighterYellowColor'"
    [class.light-blue-color]="type === 'lightBlueColor'"
    [class.light-yellow-color]="type === 'lightYellowColor'"
    [class.light-green]="type === 'lightGreen'"
    [class.grey]="type === 'grey'"
    [class.light-grey]="type === 'light-grey'"
    [class.small]="small"
    [class.large]="large"
    [class.status]="statusTag"
    [class.twoLinesLabel]="twoLinesLabel"
    [class.new-feature]="type === 'new-feature'"
  >
    @if (avatar) {
      <img
        matChipAvatar
        class="img-icon"
        [src]="avatar.pictureUrl ? avatar.pictureUrl : DEFAULT_PHOTO_URL"
      />
    }

    @if (!compact || !icon) {
      <span [class.twoLinesLabel]="twoLinesLabel">{{ label }}</span>
    }

    @if (icon) {
      <mat-icon matChipAvatar class="icon-xs" [class.img-icon]="!compact">{{
        icon
      }}</mat-icon>
    }

    <ng-content></ng-content>
  </div>
}

import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { CustomTooltipDirectiveModule } from '@arrivage-directives/custom-tooltip-directive/custom-tooltip-directive.module';
import { TranslocoModule } from '@jsverse/transloco';
import { MaterialModule } from 'src/app/material.module';

export interface NewFeatureDialogData {
  top: number;
  left: number;
  gifSource: string;
  shortTextWithGif: string;
  longTextAlone: string;
}

@Component({
  selector: 'app-new-feature-dialog',
  templateUrl: './new-feature-dialog.component.html',
  styleUrls: ['./new-feature-dialog.component.scss'],
  imports: [MaterialModule, CustomTooltipDirectiveModule, TranslocoModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewFeatureDialogComponent implements OnInit {
  static openDialog(dialog: MatDialog, data: NewFeatureDialogData) {
    const dialogRef = dialog.open<
      NewFeatureDialogComponent,
      NewFeatureDialogData
    >(NewFeatureDialogComponent, {
      data,
      autoFocus: false,
    });
    return dialogRef;
  }

  constructor(
    public dialogRef: MatDialogRef<
      NewFeatureDialogComponent,
      NewFeatureDialogData
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: NewFeatureDialogData
  ) {}

  ngOnInit() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {
      top: `${this.data.top}px`,
      left: `${this.data.left}px`,
    };
    this.dialogRef.updatePosition(matDialogConfig.position);
  }
}

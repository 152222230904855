import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PickupUtils } from '@arrivage-distribution/vendor/utils/pickup.utils';
import { TimeUtils } from '@arrivage-util/time.utils';
import { TimeOfDay } from '@arrivage/model/dist/src/model';
import { TranslocoModule } from '@jsverse/transloco';
import { addDays, addMinutes, addYears, startOfDay } from 'date-fns';
import _ from 'lodash';
import { combineLatest, startWith, Subject, takeUntil } from 'rxjs';
import { MaterialModule } from 'src/app/material.module';

export interface DateAndTimeDialogData {
  initialDate?: Date;
  initialTime?: TimeOfDay;
}

export interface DateAndTimeDialogResponse {
  date: Date;
  time: TimeOfDay;
}

@Component({
  selector: 'app-date-and-time-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    TranslocoModule,
    FlexLayoutModule,
    ReactiveFormsModule,
  ],
  templateUrl: './date-and-time-dialog.component.html',
  styleUrl: './date-and-time-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateAndTimeDialogComponent implements OnInit, OnDestroy {
  readonly TIME_OF_DAY_OPTIONS: string[] = TimeUtils.TIME_OF_DAY_OPTIONS;

  readonly MIN_SCHEDULED_TIME_IN_MINUTES = 30;

  readonly MAX_DATE = addYears(this.minDate, 1);

  form = this.fb.group({
    scheduledDate: this.fb.control<Date>(
      this.initialDateValue,
      Validators.required
    ),
    selectedTime: this.fb.nonNullable.control(this.initialTimeValue, [
      Validators.required,
      this.validateSelectedTime(),
    ]),
  });

  private unsubscribe$ = new Subject<void>();

  constructor(
    private readonly dialogRef: MatDialogRef<
      DateAndTimeDialogComponent,
      DateAndTimeDialogResponse
    >,
    private readonly fb: FormBuilder,
    private readonly changeDetectRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    private readonly data?: DateAndTimeDialogData
  ) {}

  ngOnInit(): void {
    // Update form validity on initialization to show error if the initial time is not available.
    this.updateFormValidity();

    combineLatest([
      this.scheduledDateControl.valueChanges.pipe(
        startWith(this.scheduledDateControl.value)
      ),
      this.selectedTimeControl.valueChanges.pipe(
        startWith(this.selectedTimeControl.value)
      ),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([scheduledDateValue, selectedTimeValue]) => {
        this.form.updateValueAndValidity();

        if (
          !PickupUtils.isTimeAvailable(
            selectedTimeValue,
            scheduledDateValue,
            this.minDate
          )
        ) {
          this.form.get('selectedTime')?.setErrors({ timeUnavailable: true });
        } else {
          this.form.get('selectedTime')?.setErrors(null);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isTimeAvailable(time: string): boolean {
    return PickupUtils.isTimeAvailable(
      time,
      this.scheduledDateControl.value,
      this.minDate
    );
  }

  /**
   * Returns the minimum date for the date picker if any time is available,
   * if not, returns the next day
   *
   * Time options are between 00:00 and 23:45 with 15 minutes interval
   * so 23:45 is the last available time of the day
   * @returns Date
   */
  get minDate(): Date {
    const dateWithMinScheduledTime: Date = addMinutes(
      new Date(),
      this.MIN_SCHEDULED_TIME_IN_MINUTES
    );

    const hours: number = dateWithMinScheduledTime.getHours();
    const minutes: number = dateWithMinScheduledTime.getMinutes();

    // Check if the time is 23:45 or later
    const isAfterLastTimeOfDay: boolean = hours === 23 && minutes > 45;

    return isAfterLastTimeOfDay
      ? startOfDay(addDays(dateWithMinScheduledTime, 1)) // Next day at 00:00
      : dateWithMinScheduledTime;
  }

  confirm() {
    this.updateFormValidity();

    if (this.form.invalid) return;

    const dateAndTime = {
      date: this.scheduledDateControl.value,
      time: TimeOfDay.valueToTimeOfDay(this.selectedTimeControl.value),
    };
    this.dialogRef.close(dateAndTime);
  }

  private get scheduledDateControl(): FormControl<Date> {
    return this.form.controls.scheduledDate;
  }

  private get selectedTimeControl(): FormControl<string> {
    return this.form.controls.selectedTime;
  }

  private get initialDateValue(): Date {
    if (this.data?.initialDate) return this.data.initialDate;

    return this.minDate;
  }

  private get initialTimeValue(): string {
    if (this.data?.initialTime)
      return TimeOfDay.timeOfDayToValue(this.data.initialTime);

    return _.find(TimeUtils.TIME_OF_DAY_OPTIONS, (timeOfDay) =>
      PickupUtils.isTimeAvailable(timeOfDay, this.minDate, this.minDate)
    );
  }

  private validateSelectedTime(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent as FormGroup;
      if (!formGroup) return null;

      const scheduledDate = formGroup.get('scheduledDate')?.value;
      if (!scheduledDate) return null;

      const isValid = PickupUtils.isTimeAvailable(
        control.value,
        scheduledDate,
        this.minDate
      );

      return isValid ? null : { timeUnavailable: true };
    };
  }

  private updateFormValidity(): void {
    this.form.markAllAsTouched();
    this.form.markAsDirty();
    this.scheduledDateControl.updateValueAndValidity();
    this.selectedTimeControl.updateValueAndValidity();

    // Error won't show without this if current time passes the minimum time
    this.changeDetectRef.detectChanges();
  }
}

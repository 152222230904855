import { Timestamp } from '@angular/fire/firestore';
import { WeekDays } from '@arrivage/model/dist/src/model/delivery';
import { LocalDate } from '@arrivage/model/dist/src/model/pickup';
import { isValid, isWithinInterval } from 'date-fns';
import { frCA } from 'date-fns/locale';

export namespace DateUtils {
  interface SerializedTimestamp {
    _seconds: number;
    _nanoseconds: number;
  }

  export function toDate(
    d: Timestamp | SerializedTimestamp | Date | null
  ): Date {
    if (!d) {
      return null;
    }

    if (d instanceof Timestamp) {
      return d.toDate();
    }

    if (d instanceof Date) {
      return d;
    }

    if (
      (<SerializedTimestamp>d)?._seconds !== undefined &&
      (<SerializedTimestamp>d)?._nanoseconds !== undefined
    ) {
      return new Timestamp(
        (<SerializedTimestamp>d)._seconds,
        (<SerializedTimestamp>d)._nanoseconds
      ).toDate();
    }

    throw new Error('Unsupported date type');
  }

  export function countDaysBetween(
    value: Date | number,
    absoluteValue: boolean = true,
    isRound: boolean = true
  ): number {
    if (value) {
      const countDaysDecimal =
        ((typeof value === 'number' ? value : value.valueOf()) -
          Date.now().valueOf()) /
        (1000 * 3600 * 24);
      const countDays = isRound
        ? Math.round(countDaysDecimal)
        : Math.ceil(countDaysDecimal);
      if (absoluteValue) {
        return Math.abs(countDays);
      } else {
        return countDays;
      }
    }
  }

  export function durationStringShortener(duration: Duration, locale: Locale) {
    let res = '';

    if (duration.days > 0) {
      res += duration.days + (locale === frCA ? ' j' : ' d');
    }

    if (duration.hours > 0) {
      res += (res.length === 0 ? '' : ' ') + duration.hours + ' h';
    }

    if (duration.minutes > 0) {
      res += (res.length === 0 ? '' : ' ') + duration.minutes + ' m';
    }

    if (
      duration.seconds !== undefined &&
      duration.seconds > 0 &&
      (duration.days === undefined || duration.days === 0)
    ) {
      res += (res.length === 0 ? '' : ' ') + duration.seconds + ' s';
    }

    // If the duration is 0, return '0 s'
    if (duration.seconds !== undefined && res.length === 0) {
      res = '0 s';
    }

    return res;
  }

  const WEEKDAY_FROM_DATE_LOCALE = 'en-CA';

  export function weekdayFromDate(date: Date): WeekDays {
    return Object.values(WeekDays).find(
      (weekDay: string) =>
        weekDay ===
        date
          .toLocaleDateString(WEEKDAY_FROM_DATE_LOCALE, { weekday: 'long' })
          .toLocaleLowerCase()
    );
  }

  export function weekdayFromLocalDate(date: LocalDate): WeekDays {
    return weekdayFromDate(LocalDate.toDate(date));
  }

  export function formatDate(date: Date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(date.getDate()).padStart(2, '0')}`;
  }

  export function startOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  export function isDateInDateRange(date: Date, dateRange: Interval): boolean {
    if (!date) return false;

    const isDateValid = isValid(date);
    if (!isDateValid) return false;
    if (!dateRange.start || !dateRange.end) return false;

    return isWithinInterval(date, dateRange);
  }

  /**
   * Returns a formatted date string without spaces using the given format
   * Example: 'YYYYMMDD' or 'DDMM'
   * @param date
   * @param dateFormat An array of date format keys, each corresponding to a date part. Default: ['YYYY', 'MM', 'DD']
   * @returns
   */
  export function formatDateTo(
    date: Date,
    dateFormat: ('YYYY' | 'MM' | 'DD')[] = ['YYYY', 'MM', 'DD']
  ): string {
    const year = String(date.getFullYear()); // YYYY
    const month = String(date.getMonth() + 1).padStart(2, '0'); // MM (Month, with leading zero. Ex: 01)
    const day = String(date.getDate()).padStart(2, '0'); // DD (Day, with leading zero)

    // Create a mapping between the format keys and the corresponding date value
    const dateValueMap: Record<'YYYY' | 'MM' | 'DD', string> = {
      YYYY: year,
      MM: month,
      DD: day,
    };

    const format: ('YYYY' | 'MM' | 'DD')[] =
      dateFormat?.length > 0 ? dateFormat : ['YYYY', 'MM', 'DD'];

    return format.map((part) => dateValueMap[part]).join('');
  }
}

import { Injectable } from '@angular/core';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { SnackbarService } from '@arrivage-snackbar/snackbar.service';
import { EntityFeedback } from '@arrivage-store/feedback/feedback-params.model';
import { createBaseEffects } from '@arrivage-store/generators';

import { ScheduledOfferService } from '../services/scheduled-offer.service';
import { State } from './scheduled-offer.state';

import * as scheduledOfferActions from './scheduled-offer.actions';
import * as scheduledOfferSelectors from './scheduled-offer.selectors';

interface ScheduledOfferFeedback extends EntityFeedback {
  apply: string;
}

export const ScheduledOfferFeedback: ScheduledOfferFeedback = {
  add: 'add_scheduled_offer',
  update: 'update_scheduled_offer',
  remove: 'remove_scheduled_offer',
  //error
  query: 'query_scheduled_offers',
  get_active_item: 'get_active_scheduled_offer',
  apply: 'apply_scheduled_offer',
};

@Injectable()
export class ScheduledOfferEffects {
  query$;
  add$;
  set$;
  update$;
  remove$;

  getActiveItem$;
  displayQueryFailure$;

  displayGetActiveItemFailure$;

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private service: ScheduledOfferService,
    private snackbarService: SnackbarService
  ) {
    ({
      query: this.query$,
      add: this.add$,
      set: this.set$,
      update: this.update$,
      remove: this.remove$,
      getActiveItem: this.getActiveItem$,
      displayQueryFailure: this.displayQueryFailure$,
      displayGetActiveItemFailure: this.displayGetActiveItemFailure$,
    } = createBaseEffects(
      this.actions$,
      this.store,
      scheduledOfferActions,
      scheduledOfferSelectors,
      this.service,
      ScheduledOfferFeedback,
      this.snackbarService
    ));
  }
}

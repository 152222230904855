<ng-container *transloco="let t">
  @if (data.gifSource) {
    <div
      class="new-feature-tooltip"
      data-testid="new-feature-tooltip"
      [style.top.px]="data.top"
      [style.left.px]="data.left"
    >
      @if (data.shortTextWithGif) {
        <div class="new-feature-tooltip-text">
          <mat-icon class="material-icons-outlined no-shrink" color="white"
            >info</mat-icon
          >
          <span class="text-m bold">{{ data.shortTextWithGif }}</span>
        </div>
      }
      @if (data.gifSource) {
        <img class="new-feature-gif" [src]="data.gifSource" />
      }
      <button
        mat-raised-button
        color="accent"
        type="button"
        class="new-feature-tooltip-close"
        matDialogClose
        data-testid="new-feature-tooltip-close"
      >
        {{ t('label.new-feature.close') }}
      </button>
    </div>
  } @else if (data.longTextAlone) {
    <div
      class="new-feature-tooltip-text-only"
      data-testid="new-feature-tooltip-text-only"
      [style.top.px]="data.top"
      [style.left.px]="data.left"
    >
      <mat-icon class="material-icons-outlined no-shrink" color="white"
        >info</mat-icon
      >
      <span class="text-m bold">{{ data.longTextAlone }}</span>
    </div>
  }
</ng-container>

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsFacade } from '@arrivage-analytics/api/analytics.facade';
import { NewFeatureDialogComponent } from '@arrivage-components/new-feature-dialog/new-feature-dialog.component';
import { CustomTooltipDirectiveModule } from '@arrivage-directives/custom-tooltip-directive/custom-tooltip-directive.module';
import { TranslocoModule } from '@jsverse/transloco';
import { MaterialModule } from 'src/app/material.module';
import { LabelComponent } from '../label.component';
import { LabelModule } from '../label.module';

@Component({
  selector: 'app-new-feature-label',
  templateUrl: './new-feature-label.component.html',
  styleUrls: ['./new-feature-label.component.scss'],
  standalone: true,
  imports: [
    LabelModule,
    CustomTooltipDirectiveModule,
    CommonModule,
    MaterialModule,
    TranslocoModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewFeatureLabelComponent {
  readonly MARGIN_TOOLTIP = 8;

  // The name of the feature to display
  @Input()
  featureName: string;

  /**
   * Top and left are used to position the label
   * relatively to the parent element.
   *
   * top: Usually, a higher value will move the label further down.
   * left: Usually, a higher value will move the label further to the right.
   */
  @Input()
  top: number = 0;

  @Input()
  left: number = 0;

  // The source of the gif to display
  @Input()
  gifSource: string;

  // The text to display in the tooltip
  @Input()
  shortTextWithGif: string;

  @Input()
  longTextAlone: string;

  @Input()
  tooltipPosition: 'top' | 'right' | 'bottom' | 'left' = 'top';

  @Input()
  positionAbsolute: boolean = true;

  @Input()
  compact: boolean = false;

  @Input()
  clickable: boolean = true;

  @Input()
  pointerCursor: boolean = false;

  @ViewChild(LabelComponent)
  label: LabelComponent;

  constructor(
    private dialog: MatDialog,
    private analytics: AnalyticsFacade
  ) {}

  handleNewFeatureClick() {
    this.analytics.logClickOnNewFeatureLabel(this.featureName);

    const position: DOMRect =
      this.label.element.nativeElement.getBoundingClientRect();
    NewFeatureDialogComponent.openDialog(this.dialog, {
      top: position.top + position.height + this.MARGIN_TOOLTIP,
      left: position.left,
      gifSource: this.gifSource,
      shortTextWithGif: this.shortTextWithGif,
      longTextAlone: this.longTextAlone,
    });
  }
}

<!-- SIDE NAVIGATION-->
<ng-container *transloco="let t; read: 'navigation'">
  <mat-card-content
    fxLayout="column"
    class="sidenav-content"
    [class.credit-banner-opened]="
      activeOrganization?.isSeller &&
      !isPremiumAccount &&
      creditAccount?.balance?.amount <= 0
    "
  >
    <article *ngIf="isExpanded" class="sidenav-header">
      <div fxLayout="column" fxLayoutGap="1rem" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center">
          <div class="picture-container">
            <app-upload-picture
              [currentPictureURL]="
                activeOrganization.logoUrl
                  ? activeOrganization.logoUrl
                  : DEFAULT_VENDOR_PICTURE
              "
              [hasDirectEdit]="true"
              [imageCssClass]="
                activeOrganization.logoUrl ? 'upload-logo' : 'default-logo'
              "
              [maintainCropperAspectRatio]="false"
              (pictureChanges)="handleLogoEdit($event)"
            >
            </app-upload-picture>
          </div>
        </div>
        <mat-card-title class="organization-name">{{
          activeOrganization.name | uppercaseFirst
        }}</mat-card-title>
        <!-- Seller organization and the sidenav is expanded: add account type information -->
        <div *ngIf="activeOrganization.isSeller && isExpanded">
          <ng-container *ngIf="isPremiumAccount; else standardAccount">
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="text-light"
              data-testid="premium-account"
            >
              <div
                fxLayout="column"
                fxLayoutAlign="center center"
                class="text-light"
              >
                <div>{{ t('is-premium') }}</div>
                <div>{{ t('is-premium-description') }}</div>
              </div>
            </div>
          </ng-container>
          <ng-template #standardAccount>
            <div
              fxLayout="column"
              fxLayoutAlign="center center"
              class="text-light"
              data-testid="standard-account"
            >
              <div>{{ t('is-standard') }}</div>
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                [class.text-color-error]="creditAccount?.balance?.amount <= 0"
              >
                <span>{{ t('available-credit') }}:&nbsp;</span>
                <span
                  [class.clickable]="creditAccount?.balance?.amount > 0"
                  [class.clickable-error]="creditAccount?.balance?.amount <= 0"
                  [routerLink]="['/credit-account']"
                >
                  {{
                    creditAccount?.balance
                      | displayMoneyAmount
                      | translocoCurrency
                        : 'symbol'
                        : {}
                        : creditAccount?.balance.currency
                  }}
                </span>
                <a href="https://www.arrivage.com/paiement" target="blank">
                  <mat-icon
                    *ngIf="creditAccount?.balance?.amount <= 0"
                    class="text-color-error bold"
                    [customTooltip]="t('add-credit-or-upgrade')"
                    >error_outline</mat-icon
                  >
                </a>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </article>

    <mat-divider></mat-divider>

    <mat-nav-list
      fxFlex
      fxLayout="column"
      fxLayoutAlign="space-between"
      (click)="isMediumOrSmaller ? null : expandSidenav()"
    >
      <section>
        <!-- Admin -->
        <div
          class="admin"
          *ngIf="authUser?.claims?.['admin'] && isExpanded"
          fxLayout="column"
          fxLayoutGap="2.5rem"
        >
          <app-organization-selector
            [currentOrganization]="activeOrganization"
            [organizations]="allOrganizations"
            (selectOrganization)="onViewOrganizationData($event)"
            (click)="$event.stopPropagation()"
          >
          </app-organization-selector>
          <app-expansion-menu
            titleKey="admin-section"
            (click)="$event.stopPropagation()"
          >
            <app-navigation-link
              [linkDefinition]="AdminLinks.OrganizationsManagement"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            ></app-navigation-link>
            <app-navigation-link
              [linkDefinition]="AdminLinks.CreditAccounts"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            ></app-navigation-link>
            <app-navigation-link
              [linkDefinition]="AdminLinks.ManageOrganizationRelationships"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            ></app-navigation-link>
            <app-navigation-link
              [linkDefinition]="AdminLinks.Market"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            ></app-navigation-link>
            <app-navigation-link
              [linkDefinition]="AdminLinks.Reports"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            ></app-navigation-link>
            <app-navigation-link
              [linkDefinition]="AdminLinks.ManagePurchaseOrders"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            ></app-navigation-link>
          </app-expansion-menu>
        </div>
        <mat-divider
          *ngIf="authUser?.claims?.['admin'] && isExpanded"
        ></mat-divider>

        <section (click)="$event.stopPropagation()">
          <!-- Dashboard -->
          <app-navigation-link
            [isTopLink]="true"
            [linkDefinition]="CommonLinks.Dashboard"
            [activeOnExactMatch]="true"
            [indicator]="
              !isSetupCompleted(organizationSetup$ | async) &&
              activeOrganization.isSeller
            "
            [isExpanded]="isExpanded"
            (onClickEvent)="onClose()"
          ></app-navigation-link>

          <!-- Conversations -->
          <app-navigation-link
            [isTopLink]="true"
            [linkDefinition]="CommonLinks.Conversations"
            [badgeText]="
              conversationsNeedAttention
                ? (nbOfUnreadConversations | number)
                : null
            "
            [isExpanded]="isExpanded"
            (onClickEvent)="onClose()"
          ></app-navigation-link>
        </section>

        <ng-container *ngIf="activeOrganization?.isSeller">
          <section (click)="$event.stopPropagation()">
            <!-- My customers -->
            <app-navigation-link
              [isTopLink]="true"
              [linkDefinition]="VendorLinks.Customers"
              [activeOnExactMatch]="true"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            ></app-navigation-link>

            <!-- My producers -->
            <app-navigation-link
              *ngIf="activeOrganization?.isSeller"
              [isTopLink]="true"
              [linkDefinition]="VendorLinks.Producers"
              [activeOnExactMatch]="true"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            ></app-navigation-link>

            <!-- My offers -->
            <app-expansion-menu
              titleKey="offers-section"
              [isNavbarExpanded]="isExpanded"
              [isPanelExpanded]="
                isExpanded ? isActivePanel('offers-section') : true
              "
              (click)="$event.stopPropagation()"
            >
              <app-navigation-link
                [linkDefinition]="VendorLinks.Inventory"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <app-navigation-link
                [linkDefinition]="VendorLinks.PriceLists"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <app-navigation-link
                [linkDefinition]="VendorLinks.Distribution"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <app-navigation-link
                [linkDefinition]="VendorLinks.DisplayedOffers"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <!-- Scheduled offers -->
              <app-navigation-link
                [linkDefinition]="VendorLinks.ScheduledOffers"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
                @if (showNewFeatureLabelScheduledOffers()) {
                  <app-new-feature-label
                    [featureName]="scheduledOfferFeatureName"
                    [positionAbsolute]="false"
                    [pointerCursor]="true"
                    [clickable]="false"
                    [compact]="true"
                  />
                }
              </app-navigation-link>
              <mat-divider></mat-divider>
            </app-expansion-menu>

            <!-- My sales -->
            <app-expansion-menu
              titleKey="sales-section"
              [isPanelExpanded]="
                isExpanded ? isActivePanel('sales-section') : true
              "
              [isNavbarExpanded]="isExpanded"
              [indicator]="isVendorPurchaseOrdersNeedAttention"
            >
              <app-navigation-link
                [badgeText]="
                  isVendorPurchaseOrdersNeedAttention
                    ? vendorPurchaseOrderMetrics?.nbPurchaseOrdersToConfirm.nb.toString()
                    : undefined
                "
                [linkDefinition]="VendorLinks.PurchaseOrders"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <app-navigation-link
                [linkDefinition]="VendorLinks.ScheduleDeliveries"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <app-navigation-link
                [linkDefinition]="VendorLinks.Invoices"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <app-navigation-link
                [linkDefinition]="VendorLinks.SalesReport"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <!-- Commented out until we do #1604 -->
              <!-- <app-navigation-link
          [linkDefinition]="VendorLinks.AddPurchaseOrder"
          (onClickEvent)="onClose()"
          ></app-navigation-link> -->
              <!-- Commented out until we find a way to create from an empty invoice -->
              <!-- <app-navigation-link
          [linkDefinition]="VendorLinks.CreateInvoice"
          (onClickEvent)="onClose()"
          >
          </app-navigation-link> -->
              <mat-divider></mat-divider>
            </app-expansion-menu>

            <!-- HUB BUYING SECTION -->
            <app-expansion-menu
              *ngIf="activeOrganization?.isHub"
              titleKey="purchase-section"
              [isPanelExpanded]="
                isExpanded ? isActivePanel('purchase-section') : true
              "
              [isNavbarExpanded]="isExpanded"
            >
              <!-- BUYING PURCHASE ORDER -->
              <app-navigation-link
                [linkDefinition]="HubCustomerLinks.PurchaseOrders"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              />
              <!-- BUYING SCHEDULED DELIVERIES -->
              <app-navigation-link
                [linkDefinition]="HubCustomerLinks.ScheduleDeliveries"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              />
              <!-- BUYING INVOICES -->
              <app-navigation-link
                [linkDefinition]="HubCustomerLinks.Invoices"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              />
              <!-- BUYING REPORT -->
              <app-navigation-link
                [linkDefinition]="HubCustomerLinks.BuyingReport"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              />
            </app-expansion-menu>

            <!-- My Organization -->
            <app-expansion-menu
              titleKey="account-section"
              [isPanelExpanded]="
                isExpanded ? isActivePanel('account-section') : true
              "
              [isNavbarExpanded]="isExpanded"
            >
              <app-navigation-link
                [linkDefinition]="{
                  name: 'profile',
                  URL: ['/organization/' + activeOrganization.id + '/edit'],
                  icon: 'business'
                }"
                [activeOnExactMatch]="true"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <app-navigation-link
                [linkDefinition]="CommonLinks.Settings"
                [activeOnExactMatch]="true"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <app-navigation-link
                [linkDefinition]="VendorLinks.CreditAccount"
                [isExpanded]="isExpanded"
                (onClickEvent)="onClose()"
              >
              </app-navigation-link>
              <mat-divider></mat-divider>
            </app-expansion-menu>
          </section>
        </ng-container>

        <ng-container
          *ngIf="
            activeOrganization?.isSeller &&
            activeOrganization?.isBuyer &&
            isExpanded
          "
        >
          <mat-divider></mat-divider>
        </ng-container>

        <ng-container *ngIf="activeOrganization?.isBuyer">
          <section (click)="$event.stopPropagation()">
            <!-- My Providers -->
            <!-- WEB-1181 - Commented out for now since it is not very useful -->
            <!-- <app-navigation-link
            [isTopLink]="true"
            [linkDefinition]="CustomerLinks.Providers"
            (onClickEvent)="onClose()"
          ></app-navigation-link> -->

            <!-- My Offers -->
            <div matSubheader class="bold" *ngIf="isExpanded">
              {{ t('offers-section') }}
            </div>
            <app-navigation-link
              [linkDefinition]="CustomerLinks.Search"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            >
            </app-navigation-link>
            <app-navigation-link
              [linkDefinition]="CustomerLinks.Offers"
              [activeOnExactMatch]="true"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            >
            </app-navigation-link>

            <div matSubheader class="bold" *ngIf="isExpanded">
              {{ t('purchase-section') }}
            </div>
            <!-- My Purchase Orders -->
            <app-navigation-link
              [badgeText]="
                isCustomerPurchaseOrdersNeedAttention
                  ? customerPurchaseOrderMetrics?.nbPurchaseOrdersToConfirm.nb.toString()
                  : undefined
              "
              [linkDefinition]="CustomerLinks.PurchaseOrders"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            >
            </app-navigation-link>
            <!-- My Deliveries -->
            <app-navigation-link
              [linkDefinition]="CustomerLinks.ScheduleDeliveries"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            >
            </app-navigation-link>
            <!-- My Invoices -->
            <app-navigation-link
              [linkDefinition]="CustomerLinks.Invoices"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            >
            </app-navigation-link>

            <!-- Buying Report -->
            <app-navigation-link
              [linkDefinition]="CustomerLinks.BuyingReport"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            >
            </app-navigation-link>

            <div matSubheader class="bold" *ngIf="isExpanded">
              {{ t('account-section') }}
            </div>
            <app-navigation-link
              [linkDefinition]="{
                name: 'profile',
                URL: ['/organization/' + activeOrganization.id + '/edit'],
                icon: 'location_city'
              }"
              [activeOnExactMatch]="true"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            >
            </app-navigation-link>
            <app-navigation-link
              [linkDefinition]="CommonLinks.Settings"
              [activeOnExactMatch]="true"
              [isExpanded]="isExpanded"
              (onClickEvent)="onClose()"
            >
            </app-navigation-link>
          </section>
        </ng-container>
      </section>

      <section class="chevron-btn-section">
        <mat-list-item
          *ngIf="!isMediumOrSmaller"
          fxLayoutAlign="end"
          (click)="expandSidenav(); $event.stopPropagation()"
        >
          <mat-icon class="chevron-button" [ngClass]="{ rotated: isExpanded }">
            chevron_right
          </mat-icon>
        </mat-list-item>
      </section>
    </mat-nav-list>
  </mat-card-content>
</ng-container>

export interface Link {
  name: LinkName | string;
  URL: string[];
  icon: string;
}
export enum LinkName {
  DELIVERIES = 'deliveries',
  DISTRIBUTION = 'distribution',
  PICKUPS = 'pickups',
  SCHEDULED_DELIVERIES = 'scheduled_deliveries',
  CUSTOMER_SCHEDULED_DELIVERIES = 'customer_scheduled_deliveries',
  ACTIVE_OFFERS = 'active_offers',
  INVENTORY = 'inventory',
  PRICE_LISTS = 'price_lists',
  PURCHASE_ORDERS = 'purchase_orders',
  ADD_PURCHASE_ORDER = 'add_purchase_order',
  CUSTOMERS = 'customers',
  DISPLAYED_OFFERS = 'displayed_offers',
  PROVIDERS = 'providers',
  INVOICE = 'invoices',
  CREATE_INVOICE = 'create_invoice',
  RELATIONS = 'relations',
  SEARCH = 'search',
  DASHBOARD = 'dashboard',
  SETTINGS = 'settings',
  VIEW_AS = 'view_as',
  ORGANIZATIONS_MANAGEMENT = 'organizations_management',
  CREDIT_ACCOUNTS = 'credit_accounts',
  CREDIT_ACCOUNT = 'credit_account',
  MANAGE_ORGANIZATION_RELATIONSHIPS = 'manage_organization_relationships',
  MARKET = 'market',
  REPORTS = 'reports',
  CONVERSATIONS = 'conversations',
  MANAGE_PURCHASE_ORDERS = 'manage_purchase_orders',
  SALES_REPORT = 'sales_report',
  BUYING_REPORT = 'buying_report',
  PRODUCERS = 'producers',
  SCHEDULED_OFFERS = 'scheduled_offers',
}

export namespace AdminLinks {
  export const OrganizationsManagement = {
    name: LinkName.ORGANIZATIONS_MANAGEMENT,
    URL: ['/admin/organizations-management'],
    icon: 'manage_accounts',
  };
  export const CreditAccounts = {
    name: LinkName.CREDIT_ACCOUNTS,
    URL: ['/admin/credit-accounts'],
    icon: 'attach_money',
  };
  export const ManageOrganizationRelationships = {
    name: LinkName.MANAGE_ORGANIZATION_RELATIONSHIPS,
    URL: ['/admin/organization-relationships'],
    icon: 'person',
  };
  export const Market = {
    name: LinkName.MARKET,
    URL: ['/admin/market'],
    icon: 'store',
  };
  export const Reports = {
    name: LinkName.REPORTS,
    URL: ['/admin/reports'],
    icon: 'bar_chart',
  };
  export const ManagePurchaseOrders = {
    name: LinkName.MANAGE_PURCHASE_ORDERS,
    URL: ['/admin/purchase-orders'],
    icon: 'fact_check',
  };
}

export namespace CommonLinks {
  export const Dashboard = {
    name: LinkName.DASHBOARD,
    URL: ['/'],
    icon: 'dashboard',
  };
  export const Conversations = {
    name: LinkName.CONVERSATIONS,
    URL: ['/conversations'],
    icon: 'chat',
  };
  export const Settings = {
    name: LinkName.SETTINGS,
    URL: ['/organizations/settings'],
    icon: 'settings',
  };
}

export namespace VendorLinks {
  export const Inventory = {
    name: LinkName.INVENTORY,
    URL: ['/inventory'],
    icon: 'list',
  };
  export const PriceLists = {
    name: LinkName.PRICE_LISTS,
    URL: ['/price-lists'],
    icon: 'local_offer',
  };
  export const Distribution = {
    name: LinkName.DISTRIBUTION,
    URL: ['/distribution'],
    icon: 'local_shipping',
  };
  export const ScheduleDeliveries = {
    name: LinkName.SCHEDULED_DELIVERIES,
    URL: ['/vendor/scheduled-deliveries'],
    icon: 'event',
  };
  export const PurchaseOrders = {
    name: LinkName.PURCHASE_ORDERS,
    URL: ['/vendor/purchase-orders'],
    icon: 'assignment',
  };
  export const AddPurchaseOrder = {
    name: LinkName.ADD_PURCHASE_ORDER,
    URL: ['/vendor/purchase-orders'],
    icon: 'note_add',
  };
  export const CreateInvoice = {
    name: LinkName.CREATE_INVOICE,
    URL: ['/vendor/invoices/new'],
    icon: 'note_add',
  };
  export const Invoices = {
    name: LinkName.INVOICE,
    URL: ['/vendor/invoices'],
    icon: 'request_quote',
  };
  export const SalesReport = {
    name: LinkName.SALES_REPORT,
    URL: ['/vendor/sales-report'],
    icon: 'insert_chart',
  };
  export const Customers = {
    name: LinkName.CUSTOMERS,
    URL: ['/vendor/relationships'],
    icon: 'contacts',
  };
  export const DisplayedOffers = {
    name: LinkName.DISPLAYED_OFFERS,
    URL: ['/vendor/offers'],
    icon: 'connect_without_contact',
  };
  export const CreditAccount = {
    name: LinkName.CREDIT_ACCOUNT,
    URL: ['/credit-account'],
    icon: 'business_center',
  };
  export const Producers = {
    name: LinkName.PRODUCERS,
    URL: ['/producers'],
    icon: 'groups',
  };
  export const ScheduledOffers = {
    name: LinkName.SCHEDULED_OFFERS,
    URL: ['/vendor/scheduled-offers'],
    icon: 'more_time',
  };
}

export namespace HubCustomerLinks {
  export const PurchaseOrders = {
    name: LinkName.PURCHASE_ORDERS,
    URL: ['/vendor/hub/purchase-orders'],
    icon: 'assignment',
  };
  export const ScheduleDeliveries = {
    name: LinkName.CUSTOMER_SCHEDULED_DELIVERIES,
    URL: ['/vendor/hub/scheduled-deliveries'],
    icon: 'event',
  };
  export const Invoices = {
    name: LinkName.INVOICE,
    URL: ['/vendor/hub/invoices'],
    icon: 'request_quote',
  };
  export const BuyingReport = {
    name: LinkName.BUYING_REPORT,
    URL: ['/vendor/hub/buying-report'],
    icon: 'insert_chart',
  };
}

export namespace CustomerLinks {
  export const Offers = {
    name: LinkName.ACTIVE_OFFERS,
    URL: ['/customer/offers'],
    icon: 'store',
  };
  export const Search = {
    name: LinkName.SEARCH,
    URL: ['/customer/search'],
    icon: 'storefront',
  };
  export const ScheduleDeliveries = {
    name: LinkName.CUSTOMER_SCHEDULED_DELIVERIES,
    URL: ['/customer/scheduled-deliveries'],
    icon: 'event',
  };
  export const PurchaseOrders = {
    name: LinkName.PURCHASE_ORDERS,
    URL: ['/customer/purchase-orders'],
    icon: 'assignment',
  };
  export const Invoices = {
    name: LinkName.INVOICE,
    URL: ['/customer/invoices'],
    icon: 'attach_money',
  };
  export const Providers = {
    name: LinkName.PROVIDERS,
    URL: ['/customer/relationships'],
    icon: 'account_circle',
  };
  export const BuyingReport = {
    name: LinkName.BUYING_REPORT,
    URL: ['/customer/buying-report'],
    icon: 'insert_chart',
  };
  export const list: Link[] = [
    Offers,
    Search,
    ScheduleDeliveries,
    PurchaseOrders,
    Invoices,
    Providers,
  ];
}

import { Injectable } from '@angular/core';

import { TranslocoService } from '@jsverse/transloco';

/* A new feature is an object with the following properties:
    - name: common name of the feature (used as a key)
    - expirationTimestamp: expirationTimestamp of the new feature label expiration
    - gifUrl: the url of the gif to display
    - shortTextWithGif: the text to display in the tooltip with the gif
    - longTextAlone: the text to display in the tooltip without the gif
*/
export interface NewFeature {
  expirationTimestamp: Date;
  gifUrl?: string;
  shortTextWithGif?: string;
  longTextAlone?: string;
}

// We define the new features here
export enum FEATURES {
  TEST = 'test', // This is a feature to use in the tests
  STEPPER = 'stepper',
  SCHEDULED_OFFER = 'scheduled-offer',
}

// We define the expiration date of the new features here
export enum FEATURES_EXPIRATION {
  STEPPER = 1738386000000, // 2025-02-01
  SCHEDULED_OFFER = 1740805200000, // 2025-03-01
}

@Injectable({
  providedIn: 'root',
})
export class NewFeatureService {
  /* The new features to display in the app
        Example:
        FEATURES.CHATBOT: {
            expirationTimestamp: FEATURES_EXPIRATION.CHATBOT,
            gifUrl: this.getGifUrl(FEATURES.CHATBOT),
            shortTextWithGif: this.getText(FEATURES.CHATBOT),
        }
    */
  readonly NEW_FEATURES_DICTIONARY: { [key: string]: NewFeature } = {
    [FEATURES.STEPPER]: {
      expirationTimestamp: new Date(FEATURES_EXPIRATION.STEPPER),
      gifUrl: this.getGifUrl(FEATURES.STEPPER),
      shortTextWithGif: this.getShortText(FEATURES.STEPPER),
    },

    [FEATURES.SCHEDULED_OFFER]: {
      expirationTimestamp: new Date(FEATURES_EXPIRATION.SCHEDULED_OFFER),
      longTextAlone: this.getLongText(FEATURES.SCHEDULED_OFFER),
    },
  };

  constructor(private translocoService: TranslocoService) {}

  shouldDisplayLabel(feature: FEATURES): boolean {
    const newFeature: NewFeature = this.NEW_FEATURES_DICTIONARY[feature];
    if (!newFeature) {
      return false;
    }

    return Date.now() < newFeature.expirationTimestamp.getTime();
  }

  private getShortText(feature: string): string {
    return this.translocoService.translate(
      'new-features-text-short.' + feature
    );
  }

  private getLongText(feature: string): string {
    return this.translocoService.translate('new-features-text-long.' + feature);
  }

  private getGifUrl(feature: string): string {
    return 'assets/gifs/new-feature/' + feature + '.gif';
  }
}

import { Component, Input } from '@angular/core';

export type SystemMessageType = 'success' | 'info' | 'warning' | 'error';

export interface Linkable {
  name: string;
  link: (args?: { [arg: string]: any }) => void;
}

@Component({
  selector: 'app-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss'],
})
export class SystemMessageComponent {
  visible = true;

  @Input()
  type: SystemMessageType = 'success';

  @Input()
  message: string;

  @Input()
  description: string;

  @Input()
  showBorder = true;

  @Input()
  cardShadow = false;

  @Input()
  removable = false;

  @Input()
  linkable: Linkable;

  constructor() {}

  remove() {
    this.visible = false;
  }
}

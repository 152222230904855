<section *transloco="let t" class="new-feature-container">
  <app-label
    #label
    class="new-feature-label"
    icon="auto_awesome"
    data-testid="new-feature-test-id"
    [style.top.px]="top"
    [style.left.px]="left"
    [label]="t('label.new-feature.label')"
    [type]="'new-feature'"
    [small]="true"
    [bolder]="true"
    [class.position-absolute]="positionAbsolute"
    [customTooltip]="clickable ? t('label.new-feature.tooltip') : ''"
    [tooltipPosition]="tooltipPosition"
    [tooltipNormalWhiteSpace]="true"
    [style.cursor]="
      gifSource || longTextAlone || pointerCursor ? 'pointer' : 'default'
    "
    [compact]="compact"
    (click)="clickable ? handleNewFeatureClick() : null"
  ></app-label>
</section>

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@arrivage-guards/auth.guard';
import { CustomerGuard } from '@arrivage-guards/customer.guard';
import { GuestGuard } from '@arrivage-guards/guest.guard';
import { HubGuard } from '@arrivage-guards/hub-guard.guard';
import { VendorGuard } from '@arrivage-guards/vendor-guard.guard';

import { OrganizationSelectionComponent } from './organization/organization-selection/organization-selection.component';
import { UserGuard } from './user/guards/user.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard, UserGuard],
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'conversations',
    canActivate: [AuthGuard, UserGuard],
    loadChildren: () =>
      import('./conversations/conversations.module').then(
        (m) => m.ConversationsModule
      ),
  },
  {
    path: 'public',
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'ext',
    loadChildren: () =>
      import('./external/external.module').then((m) => m.ExternalModule),
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'organization/selection',
    pathMatch: 'full',
    canActivate: [AuthGuard, UserGuard],
    component: OrganizationSelectionComponent,
  },
  {
    path: 'organization',
    canActivate: [AuthGuard, UserGuard],
    loadChildren: () =>
      import('./organization/organization.module').then(
        (m) => m.OrganizationModule
      ),
  },
  {
    path: 'authentication',
    canActivate: [GuestGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'price-lists',
    canActivate: [AuthGuard, UserGuard, VendorGuard],
    loadChildren: () =>
      import('./price-lists/price-list.module').then((m) => m.PriceListModule),
  },
  {
    path: 'products',
    canActivate: [AuthGuard, UserGuard, VendorGuard],
    loadChildren: () =>
      import('./products/products.module').then((m) => m.ProductsModule),
  },
  {
    path: 'formats',
    canActivate: [AuthGuard, UserGuard, VendorGuard],
    loadChildren: () =>
      import('./formats/formats.module').then((m) => m.FormatsModule),
  },
  {
    path: 'inventory',
    canActivate: [AuthGuard, UserGuard, VendorGuard],
    loadChildren: () =>
      import('./inventory/inventory.module').then((m) => m.InventoryModule),
  },
  {
    path: 'distribution',
    canActivate: [AuthGuard, UserGuard, VendorGuard],
    loadChildren: () =>
      import('./distribution/vendor/vendor-distribution.module').then(
        (m) => m.VendorDistributionModule
      ),
  },
  {
    path: 'credit-account',
    canActivate: [AuthGuard, UserGuard, VendorGuard],
    loadChildren: () =>
      import('./credit-accounts/credit-accounts.module').then(
        (m) => m.CreditAccountsModule
      ),
  },
  {
    path: 'producers',
    canActivate: [AuthGuard, UserGuard, VendorGuard],
    loadChildren: () =>
      import('./producers/producers.module').then((m) => m.ProducersModule),
  },
  {
    path: 'vendor',
    canActivate: [AuthGuard, UserGuard, VendorGuard],
    children: [
      {
        path: 'invoices',
        loadChildren: () =>
          import('./invoices/vendor/vendor-invoices.module').then(
            (m) => m.VendorInvoicesModule
          ),
      },
      {
        path: 'purchase-orders',
        loadChildren: () =>
          import('./purchase-orders/vendor/vendor-purchase-orders.module').then(
            (m) => m.VendorPurchaseOrdersModule
          ),
      },
      {
        path: 'scheduled-deliveries',
        loadChildren: () =>
          import(
            './scheduled-deliveries/vendor/vendor-scheduled-deliveries.module'
          ).then((m) => m.VendorScheduledDeliveriesModule),
      },
      {
        path: 'relationships',
        loadChildren: () =>
          import('./relationship/vendor/vendor-relationship.module').then(
            (m) => m.VendorRelationshipModule
          ),
      },
      {
        path: 'sales-report',
        loadChildren: () =>
          import('./purchase-report/vendor/vendor-purchase-report.module').then(
            (m) => m.VendorPurchaseReportModule
          ),
      },
      {
        path: 'offers',
        loadChildren: () =>
          import('./offers/vendor/vendor-offers.module').then(
            (m) => m.VendorOffersModule
          ),
      },
      {
        path: 'test-workflow',
        loadChildren: () =>
          import('./test-workflow/test-workflow.module').then(
            (m) => m.TestWorkflowModule
          ),
      },
      {
        path: 'hub',
        canActivate: [HubGuard],
        loadChildren: () => import('./hub/hub.module').then((m) => m.HubModule),
      },
      {
        path: 'scheduled-offers',
        loadChildren: () =>
          import('./scheduled-offers/scheduled-offers.module').then(
            (m) => m.ScheduledOffersModule
          ),
      },
    ],
  },

  {
    path: 'customer',
    canActivate: [AuthGuard, UserGuard, CustomerGuard],
    children: [
      {
        path: 'offers',
        loadChildren: () =>
          import('./offers/customer/customer-offers.module').then(
            (m) => m.CustomerOfferModule
          ),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'purchase-orders',
        loadChildren: () =>
          import(
            './purchase-orders/customer/customer-purchase-orders.module'
          ).then((m) => m.CustomerPurchaseOrdersModule),
      },
      {
        path: 'invoices',
        loadChildren: () =>
          import('./invoices/customer/customer-invoices.module').then(
            (m) => m.CustomerInvoicesModule
          ),
      },
      {
        path: 'scheduled-deliveries',
        loadChildren: () =>
          import(
            './scheduled-deliveries/customer/customer-scheduled-deliveries.module'
          ).then((m) => m.CustomerScheduledDeliveriesModule),
      },
      {
        path: 'relationships',
        loadChildren: () =>
          import('./relationship/customer/customer-relationship.module').then(
            (m) => m.CustomerRelationshipModule
          ),
      },
      {
        path: 'buying-report',
        loadChildren: () =>
          import('./purchase-report/customer/customer-report.module').then(
            (m) => m.CustomerReportModule
          ),
      },
    ],
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, UserGuard],
})
export class AppRoutingModule {}

import _ from 'lodash';

export const pagesWithoutBottomPaddingUrls = [
  '/authentication/login',
  '/authentication/signup',
  '/onboarding',
] as const;

export type PagesWithoutBottomPaddingUrl =
  (typeof pagesWithoutBottomPaddingUrls)[number];

export function isPageWithoutBottomPaddingUrl(
  url: string
): url is PagesWithoutBottomPaddingUrl {
  return _.includes(pagesWithoutBottomPaddingUrls, url);
}

import { asyncScheduler, Subscription } from 'rxjs';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { filter, observeOn, scan } from 'rxjs/operators';

import { Dictionary } from '@ngrx/entity';

import { Location } from '@angular/common';
import { AuthUser } from '@arrivage-auth/model/auth-data.model';
import { CustomerAccess } from '@arrivage-customer-access/customer-access.model';
import { isPageWithoutBottomPaddingUrl } from '@arrivage-navigation/model/navigation.model';
import {
  TOOLBAR_HEIGHT,
  TOOLBAR_HEIGHT_SMALL_SCREEN,
} from '@arrivage-navigation/utils/navigation.globals';
import { PurchaseOrderDashboardMetrics } from '@arrivage-purchase-orders/common/model/purchase-orders.model';
import { DeliveryByRelationshipId } from '@arrivage-relationship/common/model/relationship.model';
import { LogoService } from '@arrivage-services/logo.service';
import { ScreenSize } from '@arrivage-services/screen-size.service';
import {
  Conversation,
  CreditAccount,
  Delivery,
  Notification,
  Offer,
  Organization,
  OrganizationSettings,
  PremiumAccount,
  PriceList,
  User,
  WithId,
} from '@arrivage/model/dist/src/model';

interface ScrollPositionRestore {
  event: Event;
  positions: { [K: number]: number[] };
  trigger: 'imperative' | 'popstate' | 'hashchange';
  idToRestore: number;
}

@Component({
  selector: 'app-view-navigation',
  templateUrl: './view-navigation.component.html',
  styleUrls: ['../../styles/navigation.style.scss'],
  animations: [
    trigger('expandAnimation', [
      state(
        'expanded',
        style({
          width: '240px',
        })
      ),
      state(
        'collapsed',
        style({
          width: '60px',
        })
      ),
      transition('expanded => collapsed', [animate('0.5s ease')]),
    ]),
  ],
})
export class ViewNavigationComponent implements OnDestroy, AfterViewInit {
  arrivageLogoURL$ = this.logoService.arrivageWhiteLogoURL$;
  scrollbarSub: Subscription;

  @Input()
  authUser: AuthUser;

  @Input()
  user: User & WithId;

  @Input()
  allOrganizations: (Organization & WithId)[];

  @Input()
  activeOrganization: Organization & WithId;

  @Input()
  settings: OrganizationSettings;

  @Input()
  screenSize: ScreenSize;

  @Input()
  notifications: (Notification & WithId)[];

  @Input()
  customerPurchaseOrderMetrics: PurchaseOrderDashboardMetrics;

  @Input()
  vendorPurchaseOrderMetrics: PurchaseOrderDashboardMetrics;

  @Input()
  offerByPriceList: Dictionary<Offer & WithId>;

  @Input()
  priceLists: (PriceList & WithId)[];

  @Input()
  deliveries: (Delivery & WithId)[];

  @Input()
  conversations: (Conversation & WithId)[];

  @Input()
  customersAccessByPriceList: Dictionary<CustomerAccess.Customers & WithId> =
    {};

  @Input()
  deliveryByRelationship: DeliveryByRelationshipId;

  @Input()
  creditAccount: CreditAccount;

  @Input()
  premiumAccount: PremiumAccount;

  @ViewChild(MatSidenav) sidenav: MatSidenav;

  @ViewChild(MatSidenavContainer) sidenavContainer: MatSidenavContainer;

  @Output()
  notificationRead = new EventEmitter<string>();

  @Output()
  closeBetaBanner = new EventEmitter<void>();

  isExpanded: boolean = true;

  constructor(
    private logoService: LogoService,
    private router: Router,
    private platform: Platform,
    private location: Location
  ) {}

  get sidenavFixedTopGap(): number {
    if (this.screenSize?.small) {
      return TOOLBAR_HEIGHT_SMALL_SCREEN;
    }
    return TOOLBAR_HEIGHT;
  }

  get isMobile(): boolean {
    return this.platform.ANDROID || this.platform.IOS;
  }

  get isStandardAccount(): boolean {
    return (
      this.creditAccount &&
      (this.creditAccount?.orderServiceFeesInPercent > 0 ||
        !this.creditAccount?.orderServiceFeesInPercent) &&
      !this.premiumAccount?.isPremium
    );
  }

  protected isPageWithoutBottomPadding: boolean = isPageWithoutBottomPaddingUrl(
    this.location.path()
  );

  private checkPaddingState() {
    const currentPath = this.location.path();
    this.isPageWithoutBottomPadding =
      isPageWithoutBottomPaddingUrl(currentPath);
  }

  ngAfterViewInit(): void {
    this.scrollbarSub = this.router.events
      .pipe(
        filter((event) => {
          return (
            event instanceof NavigationStart || event instanceof NavigationEnd
          );
        }),
        scan<Event, ScrollPositionRestore>(
          (acc: ScrollPositionRestore, event) => ({
            event,
            positions: {
              ...acc.positions,
              ...(event instanceof NavigationStart
                ? {
                    [event.id]: [
                      this.sidenavContainer.scrollable.measureScrollOffset(
                        'left'
                      ),
                      this.sidenavContainer.scrollable.measureScrollOffset(
                        'top'
                      ),
                    ],
                  }
                : {}),
            },
            trigger:
              event instanceof NavigationStart
                ? event.navigationTrigger
                : acc.trigger,
            idToRestore:
              (event instanceof NavigationStart &&
                event.restoredState &&
                event.restoredState.navigationId + 1) ||
              acc.idToRestore,
          })
        ),
        filter(
          ({ event, trigger }: ScrollPositionRestore) =>
            event instanceof NavigationEnd && !!trigger
        ),
        observeOn(asyncScheduler)
      )
      .subscribe(({ trigger, positions, idToRestore }) => {
        if (trigger === 'imperative') {
          this.sidenavContainer.scrollable.scrollTo({
            left: 0,
            top: 0,
          });
          this.checkPaddingState();
        }
        if (trigger === 'popstate' && positions[idToRestore]) {
          this.sidenavContainer.scrollable.scrollTo({
            left: positions[idToRestore][0],
            top: positions[idToRestore][1],
          });
        }
      });
  }

  ngOnDestroy() {
    if (this.scrollbarSub) {
      this.scrollbarSub.unsubscribe();
    }
  }

  closingSidenav() {
    this.sidenav.close();
  }

  onBannerClose() {
    this.closeBetaBanner.emit();
  }

  onNotificationRead(notificationId: string) {
    this.notificationRead.emit(notificationId);
  }
}

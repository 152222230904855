<a
  *transloco="let t; read: 'navigation'"
  routerLinkActive="active"
  [routerLink]="linkDefinition.URL"
  [routerLinkActiveOptions]="{ exact: activeOnExactMatch }"
  [class.bold]="isTopLink"
  [class.sub-link]="!isTopLink"
  [customTooltip]="t('links.' + linkDefinition.name)"
  [tooltipPosition]="'right'"
  [tooltipDisabled]="isExpanded"
  (click)="onClick()"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon [ngClass]="{ 'navigation-icon': isExpanded }">
      {{ linkDefinition.icon }}
    </mat-icon>

    @if (isExpanded) {
      <span
        matBadgeColor="warn"
        matBadgeSize="small"
        [matBadge]="badgeText"
        [matBadgeHidden]="!badgeText"
        [matBadgeOverlap]="false"
      >
        {{ t('links.' + linkDefinition.name) }}
      </span>

      <span class="additional-content">
        <ng-content></ng-content>
      </span>
    }

    @if (indicator) {
      <mat-icon class="indicator" color="accent">
        fiber_manual_record
      </mat-icon>
    }
  </div>
</a>
